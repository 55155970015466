<template>
    <div>
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name"></nav-bar-component>
        <van-cell-group inset >
            <van-cell title="原头像">
                <template #label>
                    <div class="information_portrait">
                        <img v-if="old_avatar" :src="old_avatar" />
                    </div>
                </template>
            </van-cell>
            <van-cell title="新头像">
                <template #label>
                    <div class="information_portrait">
                        <van-uploader
                            v-model="fileList"
                            accept='image/jpeg,image/png'
                            max-size="2097152"
                            upload-text="点击上传"
                            :max-count="1"
                            @oversize="oversize"
                        />
                    </div>
                </template>
            </van-cell>
            <van-cell>
                <div class="sub_button">
                    <van-button round block type="primary" @click="submit">确 认</van-button>
                </div>
                <div class="sub_button">
                    <van-button round block type="default" @click="back">取 消</van-button>
                </div>
            </van-cell>
        </van-cell-group>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'

import { upload_image,my_information_request } from '@/network/user'

export default {
    name:'UserAvatarComponent',
    data(){
        return {
            old_avatar: '',
            fileList: [],
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
                fixed: false,   //固定
                placeholder: false  //等高占位
            },
            my_nav_bar_name: '修改头像',
        }
    },
    computed:{},
    methods:{
        get_my_information() {
            this.$store.commit('true_loading')
            my_information_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.old_avatar = this.$store.state.baseURL + s.result.head_portrait
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        oversize() {
            this.$toast.fail('文件大小不能超过2M')
        },
        submit() {
            if(this.fileList.length === 0) {
                this.$toast.fail('未上传新头像')
                return
            } else if(this.fileList.length > 1) {
                this.$toast.fail('只允许上传1张头像')
                return
            }

            this.$store.commit('true_loading')
            upload_image(this.fileList[0]['file'])
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('头像修改成功!')
                        this.$router.go(-1);//返回上一层
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        back() {
            this.$router.go(-1);//返回上一层
        }
    },
    filters:{},
    props:{},
    created(){
        this.get_my_information()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent
    },
    watch:{}
}
</script>

<style>
.information_portrait {
    margin-top:0.5rem;
    margin-bottom: 0.5rem;
    text-align: center;
}
.information_portrait>img {
    width: 3rem;
    height: 3rem;
    border-radius:50%
}
.information_portrait .van-image {
    border-radius:50%
}
.sub_button {
    margin: 0.5rem;
}
</style>